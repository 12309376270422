import React, { Component } from 'react';
import { Container, Col, Row} from 'react-bootstrap';


export class WarningInfo extends Component {
  render() {


      const image_style = {
	    "position":"fixed",
	    "bottom":"0px",
		"display": "block",
		"margin-left": "auto",
		"margin-right": "auto",
		"width": "70%",
		"right": 0,
    	"opacity": 0.2
      }

    return(
      <Container>
      <Row>
        <Col sm={12}>
        	<br />
          <br />
        	<h3><b>Законодательные ограничения</b></h3>
        	<br />
        	<p>
        		<p>Согласно законодательству большиства стран, валютно-обменные операции с участием физлиц могут совершаться только в банковских учреждениях, имеющих специальное разрешение (лицензию), которое выдает Центральный Банк страны.</p>

				<p><b>Совершение валютно-обменных операций между физическими лицами запрещено!</b></p>
				<p>Скупка, продажа, обмен иностранной валюты без специального разрешения (лицензии) или государственной регистрации, покушение на такие нарушения наказываются штрафом с конфискацией предмета административного правонарушения.
				Совершение этих действий повторно в течение года после наложения административного взыскания повлечет наложение более существенного штрафа также с конфискацией.
				Также, проведение обменных операций вне банков — двойной риск.</p>

				<p>Во-первых, в отношении граждан как участников незаконной валютной операции будет составлен административный протокол и наложен штраф.<br />
				Во-вторых, нелегально приобретая иностранные деньги, они рискуют получить поддельные или сувенирные банкноты либо быть обманутыми другим способом.
				</p>
			</p>
        </Col>
      </Row>
      <Row>
      <Col>
      <div>
      <img style={image_style} alt="" src="/home_logo.svg" />
      </div>
      </Col>
      </Row>

      </Container>
    )
  }
}

export default WarningInfo