import React, {Component} from 'react';
import { Alert, Container, Row, Col, Button } from 'react-bootstrap';
import Backend from '../backend';

export class VerifyEmail extends Component {

    state = {
      'hidden': true,
      'success': false,
      'message': ''
    }

    componentDidMount = () => {
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          'code': this.props.match.params.code,
        })
      };

      Backend.request('/api/verify/email', requestOptions.method, requestOptions.body).then(data => {
        this.setState({ message: data.message, 'success': data.success, 'hidden': false })
      });
    }

    render() {

        let hide_container = true;
        let variant = 'info';
        if (this.state.success && !this.state.hidden) {
          variant = 'success';
          hide_container = false;
        } else {
          variant = 'danger';
          hide_container = true;
        };

        return (
            <div>
                <Alert variant={variant} hidden={this.state.hidden}>
                    {this.state.message}
                </Alert>
                <Container hidden={hide_container}>
                  <Row>
                    <Col>
                      <br/>
                        <h2>Продолжить</h2>
                        <br />
                        <p>Теперь вы можете размещать объявления и откликаться на другие</p>
                        <br />
                        <Row>
                        <Col sm={3}>
                        <p>
                            <Button variant="primary" href="/#/deal/new">Разместить объявление</Button>
                        </p>
                        </Col>
                        <Col sm={4}>
                        <p>
                            <Button variant="link" href="/#/deal/list">Перейти к объявлениям</Button>
                        </p>
                        </Col>
                        </Row>
                    </Col>
                  </Row>
                </Container>
            </div>
        )
    }
};

export default VerifyEmail;
