import React, {Component} from 'react';
import { Navbar, Form, FormControl, Nav, Button } from 'react-bootstrap';
import {
  NavLink,
  HashRouter
} from "react-router-dom";
import Backend from '../backend';

class Mainbar extends Component {
  signout() {
    Backend.signout();
  }

  render() {
    // background-color
    if (Backend.authToken) {
    return (
      <Navbar style={{"background-color": "ghostwhite"}} sticky="top" expand="lg">
{/*        <Navbar.Brand href="/">
          <img
            src="logo.png"
            width="auto"
            height="30"
            className="d-inline-block align-top"
            alt="Прокопович"
          />{' '}
        </Navbar.Brand>*/}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <HashRouter>
              {/*<Button variant="outline-success">Search</Button>*/}
              <Button variant="primary" as={NavLink} to="/deal/new">Разместить объявление</Button>
              <Nav.Link as={NavLink} to="/deal/list">Объявления</Nav.Link>
              <Nav.Link as={NavLink} to="/info">Информация</Nav.Link>
            </HashRouter>
          </Nav>
          <Nav>
            <HashRouter>
              <Nav.Link as={NavLink} to="/deal/messages/list">Сообщения</Nav.Link>
              <Nav.Link as={NavLink} to="/deal/my">Мои объявления</Nav.Link>
              <Nav.Link as={NavLink} to="/user/settings">Настройки</Nav.Link>
              <Nav.Link onClick={() => this.signout()}>Выйти</Nav.Link>
            </HashRouter>
          </Nav>
          <Form inline hidden>
            <FormControl type="text" placeholder="Поиск" className="mr-sm-2" />
            <Button variant="outline-success">Найти</Button>
          </Form>
        </Navbar.Collapse>
      </Navbar>
    )      
    } else {
    return (
      <Navbar bg="light" sticky="top" expand="lg">
{/*        <Navbar.Brand href="/">
          <img
            src="logo.png"
            width="auto"
            height="30"
            className="d-inline-block align-top"
            alt="Прокопович"
          />{' '}
        </Navbar.Brand>*/}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <HashRouter>
              <Nav.Link as={NavLink} to="/deal/new">Разместить объявление</Nav.Link>
              <Nav.Link as={NavLink} to="/">Объявления</Nav.Link>
              <Nav.Link as={NavLink} to="/info">Информация</Nav.Link>
            </HashRouter>
          </Nav>
          <Nav>
            <HashRouter>
              <Nav.Link as={NavLink} to="/deal/list">Вход</Nav.Link>
              <Nav.Link as={NavLink} to="/user/register">Регистрация</Nav.Link>
            </HashRouter>
          </Nav>
          <Form inline hidden>
            <FormControl type="text" placeholder="Поиск" className="mr-sm-2" />
            <Button variant="outline-success">Найти</Button>
          </Form>
        </Navbar.Collapse>
      </Navbar>
    )}
  }
}

export default Mainbar;
