import React, {Component} from 'react';
import Mainbar from './components/mainbar';
import {Auth, ResetPassword, ResetPasswordCode} from './components/auth';
import {UserSettingsPassword, Registration} from './components/user';
import Home from './components/home';
import DealForm from './components/deal';
import DealList from './components/deal_list';
import DealListMy from './components/deal_my';
import VerifyEmail from  './components/verify_email';
import Messages from  './components/messages';
import Backend from './backend';
import WarningInfo from './components/info';
import {
  Route,
  HashRouter
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga';


class App extends Component {
  componentDidMount(){
    document.title = "Обменник";
    ReactGA.initialize('G-QCJKRF2Y62');
    ReactGA.pageview(window.location.hash);
  }

  render() {
    if (Backend.authToken != null) {
      return (
        <div>
          <Mainbar />
          <div>
            <HashRouter>
              <Route path="//" component={Home} />
              <Route path="/deal/list" component={DealList} />
              <Route path="/deal/my" component={DealListMy} />
              <Route path="/deal/new" component={DealForm} />
              <Route path="/deal/messages/:deal_id" component={Messages} />
              <Route path="/verify/email/:code" component={VerifyEmail} />
              <Route path="/user/settings" component={UserSettingsPassword} />
              <Route path="/info" component={WarningInfo} />
            </HashRouter>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <Mainbar />
          <Auth />
            <div>
              <HashRouter>
                <Route path="//" component={Home} />
                <Route path="/verify/email/:code" component={VerifyEmail} />
                <Route path="/user/register" component={Registration} />
                <Route path="/auth/resetpassword" component={ResetPassword} />
                <Route path="/verify/resetpassword/:code" component={ResetPasswordCode} />
                <Route path="/info" component={WarningInfo} />
              </HashRouter>
            </div>
        </div>
      )
    }
  }
}

export default App;
