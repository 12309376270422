import React, { Component } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import Backend from '../backend';


export class Auth extends Component {  

  state = {
    disabled: false,
    error: null,
    email: '',
    password: '',
    hidden: false,
  }


  locationHashChanged() {
    let hash = document.location.hash
    if (["#/", "#/user/register", "#/auth/resetpassword", "#/info"].includes(hash) || hash.startsWith("#/verify")) {
      this.setState({hidden: true})
    } else {
      this.setState({hidden: false})
    }
  }

  componentWillMount() {
    this.locationHashChanged();
    window.addEventListener('hashchange', this.locationHashChanged.bind(this));
  }

  signin() {
    let { email, password } = this.state;
    this.setState({ disabled: true, error: null })
    try {
      Backend.signin(email, password);
    } catch (ex) {
      this.setState({ error: ex.message })
      this.setState({ disabled: false })
    }
  }

  render() {
    let { disabled, email, password, error, hidden } = this.state;

    let styles = {
      fullHeight: {
        minHeight: '70vh'
      },
      col: {
        margin: 'auto'
      }
    }

    return (
      <Container style={styles.fullHeight} hidden={hidden}>
        <Row style={styles.fullHeight}>
          <Col sm={{ span: 8, offset: 2 }}lg={{ span: 4, offset: 4 }} style={styles.col}>

            {error != null &&
              <Alert variant='danger'>{error}</Alert>
            }
              <center>
                <h3>Обменник</h3>
              </center>
              <br />
              <Form onSubmit={(ev) => { ev.preventDefault(); this.signin() }} >
                <Form.Group controlId="formBasicEmail">
                  <Form.Control size="lg" disabled={disabled} value={email} type="text" placeholder="Введите Email" onChange={(ev) => this.setState({ email: ev.target.value })} />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Control size="lg" disabled={disabled} value={password} type="password" placeholder="Пароль" onChange={(ev) => this.setState({ password: ev.target.value })} />
                </Form.Group>

                <Button type="submit" disabled={disabled} size="lg" variant="success" block>
                  Войти
                </Button>
                <br />
                <center>
                <Button variant="link" href="/#/user/register">Зарегистрироваться</Button><br />
                <Button variant="link" href="/#/auth/resetpassword">Восстановить пароль</Button>
                </center>
              </Form>

          </Col>
        </Row>
      </Container>
    )
  }
};


export class ResetPassword extends Component {
  state = {
    error: null,
    disabled: false,
    email: "",
    success: false,
  }

  sendEmail = (e) => {
    this.setState({'disabled': true});
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        'email': this.state.email,
      })
    };
    Backend.request('/api/auth/resetpassword', requestOptions.method, requestOptions.body).then(data => {
      if (data.success) {
        this.setState({ success: data.message });
      } else {
        this.setState({'disabled': false});
      }
    });
  }

  render() {

    let { error, disabled, email, success } = this.state;

    let styles = {
      fullHeight: {
        minHeight: '70vh'
      },
      col: {
        margin: 'auto'
      }
    }

      return(
        <Container>
          <Row style={styles.fullHeight}>
            <Col sm={{ span: 8, offset: 2 }}lg={{ span: 4, offset: 4 }} style={styles.col}>

              {error != null &&
                <Alert variant='danger' onClose={() => this.setState({error: null})} dismissible>{error}</Alert>
              }
              {success &&
                <Alert variant='success' onClose={() => this.setState({success: null})} dismissible>{success}</Alert>
              }
                <center>
                  <h3>Восстановление пароля</h3>
                </center>
                <br />
                <Form onSubmit={(e) => { e.preventDefault(); this.sendEmail(e) }} >
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control size="lg" disabled={disabled} value={email} type="text" placeholder="Введите Email" onChange={(ev) => this.setState({ email: ev.target.value })} />
                  </Form.Group>

                  <Button type="submit" disabled={disabled} size="lg" variant="success" block>
                    Продолжить
                  </Button>
                  <br />
                  <center>
                  <Button variant="link" href="/#/user/register">Зарегистрироваться</Button><br />
                  <Button variant="link" href="/#/deal/list">Авторизоваться</Button>
                  </center>
                </Form>

            </Col>
          </Row>
        </Container>
      )
  }
};


export class ResetPasswordCode extends Component {
  state = {
    error: null,
    disabled: false,
    success: false,
    password: null,
    password_confirmation: null,
    temp_password: null,
  }

  validate = () => {
    let { password, password_confirmation } = this.state;
    if (password !== password_confirmation) {
      throw new Error("Пароли не совпадают");
    }
    return true
  }

  updatePassword = () => {
    this.setState({ disabled: true });
    let validated = false;
    try {
      validated = this.validate();
    } catch (ex) {
      this.setState({ error: ex.message, disabled: false });
    };
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        'code': this.state.code,
        'password': this.state.password,
        'password_confirmation': this.state.password_confirmation,
        'secure': this.state.temp_password,
      })
    };
    if (validated) {
      Backend.request('/api/auth/resetpassword/update', requestOptions.method, requestOptions.body).then(data => {
        if (data.success) {
          this.setState({"success": data.message, "disabled": true})
        } else {
          this.setState({"error": data.message, "disabled": true})
        }
      });
    }
  }


  validateCode = () => {
    const requestOptions = {
      method: 'PUT',
      body: JSON.stringify({
        'code': this.props.match.params.code,
      })
    };
    Backend.request('/api/auth/resetpassword', requestOptions.method, requestOptions.body).then(data => {
      if (data.success) {
        this.setState({"temp_password": data.message, "code": this.props.match.params.code})
      } else {
        this.setState({"error": data.message, "disabled": true})
      }
    });
  }

  componentWillMount() {
    this.validateCode();
  }

  onChange = (e) => {
    let data = {};
    data[e.target.name] = e.target.value;
    data['error'] = null;
    this.setState(data);
  }

  render() {

    let { error, disabled, success } = this.state;

    let styles = {
      fullHeight: {
        minHeight: '70vh'
      },
      col: {
        margin: 'auto'
      }
    }

    return (
      <Container>
        <Row style={styles.fullHeight}>
          <Col sm={{ span: 8, offset: 2 }}lg={{ span: 4, offset: 4 }} style={styles.col}>
              {error != null &&
                <Alert variant='danger' onClose={() => this.setState({error: null})} dismissible>{error}</Alert>
              }
              {success &&
                <Alert variant='success' onClose={() => this.setState({success: null})} dismissible>{success}</Alert>
              }
              <center>
                <h3>Новый пароль</h3>
              </center>
              <br />
              <Form onSubmit={(e) => { e.preventDefault(); this.updatePassword() }} >
                <Form.Group controlId="password">
                  <Form.Control size="lg" type="password" required disabled={disabled} name="password" placeholder="Введите новый пароль" onChange={this.onChange} />
                </Form.Group>
                <Form.Group controlId="password_confirm">
                  <Form.Control size="lg" type="password" required disabled={disabled} name="password_confirmation" placeholder="Повторите пароль" onChange={this.onChange} />
                </Form.Group>
                <Button type="submit" size="lg" variant="success" disabled={disabled} block>
                  Продолжить
                </Button>
                <br />
                <center>
                <Button variant="link" href="/#/user/register">Зарегистрироваться</Button><br />
                <Button variant="link" href="/#/deal/list">Авторизоваться</Button>
                </center>
              </Form>
          </Col>
        </Row>
      </Container>
    )
  }
};

export default Auth
