import React, { Component, useState } from 'react';
import { Form, Container, Col, Row, Button, Alert, Tab, Nav, Table, ListGroup, Tabs } from 'react-bootstrap';
import Backend from '../backend';
import {UserDate} from '../utils';


const MessageForm = ({disabled, deal_id, user_to, getCurrentUserMessages}) => {

  let message = "Используйте сообщения чтобы договориться о времени и месте сделки";
  const [text, setText] = useState("")


  const onChange = (e) => {
    setText(e.target.value);
  }


  const sendMessage = () => {

    disabled = true;
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        'deal_id': deal_id,
        'text': text,
        'user_to': user_to,
      })
    };

    if (deal_id && text !== "") {
      Backend.request('/api/message', requestOptions.method, requestOptions.body).then(data => {
        disabled = false;
        setText("");
        getCurrentUserMessages(user_to, deal_id);
      });
    }
  }


    if (disabled) {message = "Вы не можете откликнуться на своё объявление, ждите отклика."}
    return(
      <Form>
        <Form.Group controlId={deal_id}>
          <Form.Label>Сообщение:</Form.Label>
          <Form.Control as="textarea" placeholder={message} rows="3" disabled={disabled} value={text} onChange={onChange} />
        </Form.Group>
        <Button variant="primary" onClick={sendMessage} disabled={disabled}>
          Отправить
        </Button>
      </Form>
    )
}


const DealRow = ({current_deal}) => {

  const types = {
    "cash": "Наличные",
    "card": "Карта",
    "country_bank": "Банк",
    "word_bank": "Иност Банк",
  };

  const statuses = {
    "open": "Открыто",
    "reserve": "Выполняется",
    "moderate": "Исполнено",
    "refuse": "Отказ",
    "close": "Закрыто"
  };

  if (!current_deal) {
    return <p></p>
  }

  return(
    <Row>
      <Col>
      <font size="2">
          <Table striped bordered hover responsive size="md">
            <tbody>
              <tr>
                <td>{UserDate(current_deal.date)}</td>
                <td>{types[current_deal.type_from]}</td>
                <td bgcolor='#ffecef'>{current_deal.currency_from}</td>
                <td bgcolor='#ffecef'>{current_deal.sum_from}</td>
                <td bgcolor='#e2ffe0'>{current_deal.currency_to}</td>
                <td bgcolor='#e2ffe0'>{current_deal.sum_to}</td>
                <td bgcolor='#fff9c0'>{(current_deal.sum_to / current_deal.sum_from).toFixed(2)}{" | "}{(current_deal.sum_from / current_deal.sum_to).toFixed(2)}</td>
                <td>{types[current_deal.type_to]}</td>
                <td>{statuses[current_deal.status]}</td>
              </tr>
              <tr>
                <td colSpan="9">Пользователь: <b>{current_deal.user.name || "Имя не указано" }</b> | {current_deal.country.name_ru}, {current_deal.region.name_ru || "регион любой"}, город {current_deal.city.name_ru || "не указан"}</td>                
              </tr>
            </tbody>
          </Table>
      </font>
    </Col>
    </Row>
  )
}


export class Messages extends Component {
  state = {
    messages: [],
    current_deal: null, 
    deal_id: this.props.match.params.deal_id,
    deal_list: [],
    loaded: false,
    user_id: null,
    users: [],
    current_user: null,
    current_user_messages: [],
    userAtiveKey: 0,
  }

  // updateCurrentDeal = () => {
  //   let deal_id = this.state.deal_id;
  //   if (deal_id && deal_id !== 'list') {
  //     Backend.request("/api/deal/all/" + deal_id).then((data) => {
  //     this.setState({ current_deal: data.deal });
  //     });
  //   }
  // }

  getCurrentUserMessages = (user_id, deal_id) => {
      if (user_id && deal_id) {
      Backend.request("/api/messages/deal_user/" + deal_id + "/" + user_id).then((data) => {
        this.setState({ current_user_messages: data.messages});
      });
    }
  }


  getDealUsers = (deal_id) => {
    Backend.request("/api/messages/deal/" + deal_id).then((data) => {
    this.setState({ users: data.users });
    if (data.users[0]) {
      this.setState({current_user: data.users[0]});
      this.getCurrentUserMessages(data.users[0]._id, deal_id);
    } else {
      this.getCurrentUserMessages(data.users_id, deal_id);
    }
    });
  }

  activeDealNav = (activeKey) => {
    this.setState({"current_deal": this.state.deal_list[activeKey], "current_user": this.state.deal_list[activeKey].user, "userAtiveKey": 0})
    this.getDealUsers(this.state.deal_list[activeKey]._id);
  }

  activeUserNav = (activeKey) => {
    console.log(activeKey);
    this.setState({"current_user": this.state.users[activeKey], "userAtiveKey": activeKey});
    this.getCurrentUserMessages(this.state.users[activeKey]._id, this.state.current_deal._id);
  }

  componentWillMount = () => {
    let url = "/api/messages/deals";
    let deal_id = this.state.deal_id;
    if (deal_id && deal_id !== 'list') {
      url = url + '?deal_id=' + deal_id;
    }
    Backend.request(url).then((data) => {
    this.setState({ "deal_list": data.list, "user_id": data.user_id, "loaded": true });
    if (data.list.length > 0) {
      this.setState({"current_deal": data.list[0], "current_user": data.list[0].user});
      this.getDealUsers(data.list[0]._id);
    };
    });
  }

  render() {

    const getMe = (user_from) => {
      const me = {color: "grey"};
      const they = {color: "green"};
      if (user_from === this.state.user_id) {
        return (
          <span style={me}><b>Вы: </b></span>
        )
      } else {
        return (
          <span style={they}><b>{this.state.current_user.name}: </b></span>
        )
      }
    }

    let deal_list = this.state.deal_list;
    let current_deal = this.state.current_deal;
    let current_user_messages = this.state.current_user_messages;
    let users = this.state.users;
    let current_user = this.state.current_user;

    if (!current_deal) {
      return(<Alert variant="primary" hidden={!this.state.loaded}>Вы еще не откликались ни на одно объявление или никто не откликнулся на ваше <span role="img" aria-label="не грусти">😒</span></Alert>)
    } 
      else {
        let disabled_form = (current_user._id === this.state.user_id);
        return(
          <Container>
          <br />
          <Row>
            <Col sm={12}>
              <Tab.Container defaultActiveKey={0}>
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column" activeKey={0} onSelect={this.activeDealNav}>
                      {Object.keys(deal_list).map((index_nav) => (
                        <Nav.Item key={index_nav}>
                          <Nav.Link eventKey={index_nav}>
                          {deal_list[index_nav].currency_from}{" "}
                          {deal_list[index_nav].sum_from}{" | "}
                          {deal_list[index_nav].currency_to}{" "}
                          {deal_list[index_nav].sum_to}{" "}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <DealRow current_deal={current_deal} />
                      <Tabs defaultActiveKey={0} activeKey={this.state.userAtiveKey} onSelect={this.activeUserNav} unmountOnExit={true} mountOnEnter={true}>
                        {Object.keys(users).map((index_tab) => (
                        <Tab eventKey={index_tab} key={index_tab} title={users[index_tab].name}>
                          <ListGroup key={users[index_tab]._id} variant="flush">
                          {Object.keys(current_user_messages).map((index_list) => (
                            <ListGroup.Item key={index_list}>
                            <font size="2">
                            <p>
                            {getMe(current_user_messages[index_list].user_from)}{UserDate(current_user_messages[index_list].date)}{": "}
                            </p>
                            </font>
                            {current_user_messages[index_list].text}
                            </ListGroup.Item>
                          ))}
                          </ListGroup>
                        </Tab>
                        ))}
                      </Tabs>
                      <br />
                      <MessageForm disabled={disabled_form} deal_id={current_deal._id} user_to={current_user._id} getCurrentUserMessages={this.getCurrentUserMessages}/>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
          </Container>
        )}
  }

}

export default Messages
