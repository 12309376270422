class AuthRequiredError extends Error {
  constructor() {
    super('Auth Required!');
  }
}

class Backend {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API;
    this.headers = { 'Content-Type': 'application/json' };
    if (this.authToken != null)
      this.headers['Authorization'] = this.authToken;
  }

  get authToken() {
    return localStorage.getItem('authToken');
  }

  set authToken(str) {
    if (str == null) {
      localStorage.removeItem('authToken');
      delete this.headers['Authorization'];
    } else {
      localStorage.setItem('authToken', str)
      this.headers['Authorization'] = str;
    }
  }

  request = (path, method = 'GET', body = {}) => {
    let opts = {
      method: method,
      body: body,
      headers: this.headers
    };

    if (method === 'GET')
      delete opts['body'];
      
    return fetch(this.baseUrl + path, opts)
      .then(response => {
        if (response.status === 401 || response.status === 400 || response.status === 403) {
          // Auth required
          this.authToken = null;
          document.location.reload(true);
        };
        return response;
      }).then(response => response.json())
      .then((data) => {return data})
  }

  signin(email, password) {
    this.request('/api/auth', 'PUT', JSON.stringify({email,password}))
    .then((json) => {
      if (json.token) {
        this.authToken = json.token
        document.location.reload(true);
        return json.token;
      } else {
        throw new Error(json.message);
      }
    });
  }

  signup(email, password, name) {
    return this.request('/api/user', 'POST', JSON.stringify({email, password, name}))
  }

  signout() {
    this.authToken = null;
    document.location.reload(true);
    return true;
  }
}

let API = new Backend();
API.ARE = AuthRequiredError

export default API;