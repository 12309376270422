import React, { Component } from 'react';
import { BaseObjectList } from './universal/list_object';
import { ObjectFormContainer } from './universal/new_object_form';
import { Form, Button, Container, Row, Col, Alert, Jumbotron } from 'react-bootstrap';
import Backend from "../backend";
// import {Redirect} from 'react-router-dom';


export class UserList extends BaseObjectList {
    state = {
        schema: {},
        list_schema: [],
       	schema_url: "/api/schema/user",
       	api_url: "/api/user",
       	form: {},
       	alert: false,
        values: [],
        current_values: [],
        currentPage: null,
        totalPages: null,
       	title: "Пользователи",
        summary: [],
        filteredValues: [],
    } 
};


export class UserForm extends ObjectFormContainer {
    state = {
        schema: {},
        list_schema: [],
        schema_url: "/api/schema/user",
        api_url: "/api/user",
        form: {},
        alert: false,
        title: "Пользователь",
    }; 
};


export class UserSettings extends ObjectFormContainer {
    state = {
        schema: {},
        list_schema: [],
        schema_url: "/api/schema/settings",
        api_url: "/api/user/settings",
        form: {},
        alert: false,
        title: "Настройки",
        edit: true
    }; 
};


export class UserPassword extends ObjectFormContainer {
    state = {
        schema: {},
        list_schema: [],
        schema_url: "/api/schema/password",
        api_url: "/api/user/password",
        form: {},
        alert: false,
        title: "Изменение пароля",
        edit: true
    };
};


export class UserSettingsPassword extends Component {
  render() {

  return (
    <div>
      <UserSettings match={this.props.match} />
      <UserPassword match={this.props.match} />
    </div>
  )}
};


export const RegistrationForm = (props) => {
  let {email, password, password_confirmation, name, disabled, onChange, onSubmit} = props;

  return (
      <Form className="align-items-center" onSubmit={(ev) => { ev.preventDefault(); onSubmit(); }} >

        <Form.Group controlId="formBasicName">

          <Form.Control size="lg" required disabled={disabled} value={name} type="text" placeholder="Имя" onChange={(ev) => onChange('name', ev.target.value)} />
        </Form.Group>

        <Form.Group controlId="email">

          <Form.Control size="lg" required disabled={disabled} value={email} type="email" placeholder="Введите email" onChange={(ev) => onChange('email', ev.target.value)}/>
        </Form.Group>

        <br />
        <Form.Group controlId="password_new">

          <Form.Control size="lg" required disabled={disabled} value={password} id="password_new" type="password" placeholder="Пароль" onChange={(ev) => onChange('password', ev.target.value)} />
        </Form.Group>

        <Form.Group controlId="password_confirm">

          <Form.Control size="lg" required disabled={disabled} value={password_confirmation} id="password_confirm" type="password" placeholder="Подтверждение пароля" onChange={(ev) => onChange('password_confirmation', ev.target.value)} />
        </Form.Group>

        <Button type="submit" disabled={disabled} size="lg" variant="success" block>
          Отравить
        </Button>
        <br/>
        {props.children}
      </Form>
  );
};

export class Registration extends Component {

    state = {
      disabled: false,
      error: null,
      success: null,
      user: {},
      created: false,
    }

  signup() {
    let { email, password, name } = this.state.user;
    this.setState({ error: null, success: null });
    try {
      this.setState({ disabled: true })
      this.validate();
      Backend.signup(email, password, name).then((response) => {
        console.log(response);
        if (response['success']) {
          this.setState({ success: 'Пользователь успешно создан', created: true, disabled: true})
        } else {
          this.setState({error: response.message})
        }
      });
    } catch (ex) {
      this.setState({ error: ex.message });
    } finally {
      this.setState({ disabled: false })
    }
  }

  validate() {
    let { password, password_confirmation } = this.state.user;

    if (password !== password_confirmation) {
      throw new Error("Пароли не совпадают");
    }
  }

  onUserDataChange = (key, value) => {
    let {user} = this.state;
    user[key] = value;
    this.setState(user);
  }

  render() {
    let { disabled, error, success, created } = this.state;
    let { email, password, password_confirmation, name } = this.state.user;

    // if (this.state.created) {
    //   return <Redirect to="/deal/list" />
    // }
    return (
      <Container>
        <br />
        <Row hidden={created}>
          <Col sm={{ span: 8, offset: 2 }}lg={{ span: 4, offset: 4 }}>
            {error != null &&
              <Alert variant='danger' onClose={() => this.setState({error: null})} dismissible>{error}</Alert>
            }
            {success != null &&
              <Alert variant='success' onClose={() => this.setState({success: null})} dismissible>{success}</Alert>
            }
            <br />
            <h3>Регистрация в обменнике</h3>
            <br />
             <RegistrationForm onSubmit={this.signup.bind(this)} onChange={this.onUserDataChange} disabled={disabled} error={error} success={success} email={email} password={password} password_confirmation={password_confirmation} name={name} />
          </Col>
        </Row>
        <Row hidden={!created}>
          <Col>
            <Jumbotron>
              <h1>Проверьте почту!</h1>
              <br />
              <p>
                Вы успешно зарегистрировались
              </p>
              <p>
                Для продолжения перейдите по ссылке указанной в письме (письмо может быть в спаме)
              </p>
              <br />
              <p>
                <Button variant="primary" href="/#/">Вернуться на главную</Button>
              </p>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    )
  }
};


export default UserList;
