import React, {Component} from 'react';
import { Alert, Container, Button, Table, Row, Col } from 'react-bootstrap';
import Backend from '../backend';
import {UserDate} from '../utils';


export class MainPage extends Component {
    state = {
        deal_list: [],
        hidden: true
    }


    componentWillMount = () => {
      if (!Backend.authToken) {
        this.setState({hidden: false});
      };
      Backend.request("/api/deals").then((data) => {
        this.setState({ deal_list: data.list });
      });
    }

    setHidden = (hidden) => {
      this.setState({ hidden: hidden });
    }

    render() {

      const types = {
        "cash": "Наличные",
        "card": "Карта",
        "country_bank": "Банк",
        "word_bank": "Иност Банк",
      }

      const statuses = {
        "open": "Открыто",
        "reserve": "Выполняется",
        "moderate": "Исполнено",
        "refuse": "Отказ",
        "close": "Закрыто"
      } 

        return (
            <div>
                <Alert variant="info" onClose={() => this.setHidden(true)} dismissible hidden={this.state.hidden}>
                  <Alert.Heading>Обменник</Alert.Heading>
                  <p>
                  Тут ты можешь обменять валюту за пределами банков. Если по каким либо причинам обмен в банке для тебя предоставляется невозможным – найди или размести объявление среди таких же как ты.
                  </p>
                  <Button variant="outline-info" href="/#/deal/new">Разместить объявление</Button>
                </Alert>
                <br />
                <Container>
                <Row>
                <Col>
                <p>Последние объявления:</p>
                <font size="2">
                <Table striped bordered hover responsive size="md">
                  <thead>
                    <tr>
                      <th>Дата</th>
                      <th>Тип продажи</th>
                      <th>Валюта продажи</th>
                      <th>Сумма продажи</th>
                      <th>Валюта покупки</th>
                      <th>Сумма покупки</th>
                      <th>Курс</th>
                      <th>Тип покупки</th>
                      <th>Статус</th>
                    </tr>
                  </thead>
                  <tbody>
                      {Object.keys(this.state.deal_list).map((index) => (
                        <tr style={{"cursor": "pointer"}} onClick={() => (this.props.history.push('deal/messages/' + this.state.deal_list[index]._id))}>
                          <td>{UserDate(this.state.deal_list[index].date)}</td>
                          <td>{types[this.state.deal_list[index].type_from]}</td>
                          <td bgcolor='#ffe9e5'>{this.state.deal_list[index].currency_from}</td>
                          <td bgcolor='#ffe9e5'>{this.state.deal_list[index].sum_from}</td>
                          <td bgcolor='#dafff5'>{this.state.deal_list[index].currency_to}</td>
                          <td bgcolor='#dafff5'>{this.state.deal_list[index].sum_to}</td>
                          <td bgcolor='#fffacb'>{(this.state.deal_list[index].sum_to / this.state.deal_list[index].sum_from).toFixed(2)}{" | "}{(this.state.deal_list[index].sum_from / this.state.deal_list[index].sum_to).toFixed(2)}</td>
                          <td>{types[this.state.deal_list[index].type_to]}</td>
                          <td>{statuses[this.state.deal_list[index].status]}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                </font>
                </Col>
                </Row>
                </Container>
            </div>
        )
    }
};

export default MainPage;
