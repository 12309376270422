import React, { Component } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import Backend from '../backend';
import {Redirect} from 'react-router-dom';
import {UserDate} from '../utils';

export class DealList extends Component {

state = {
    deal_list: [],
    deal_id: null
}

componentWillMount = () => {
  Backend.request("/api/deal/list").then((data) => {
    this.setState({ deal_list: data.list });
  });
}

rowClick = (deal_id) => {
  this.setState({deal_id: deal_id})
}

render() {




    const types = {
      "cash": "Наличные",
      "card": "Карта",
      "country_bank": "Банк",
      "word_bank": "Иност Банк",
    };

    const statuses = {
      "open": "Открыто",
      "reserve": "Выполняется",
      "moderate": "Исполнено",
      "refuse": "Отказ",
      "close": "Закрыто"
    };

    let styles = {
      tr: {
        cursor: 'pointer'
      },
    };

    if (this.state.deal_id) {
      return <Redirect to={"/deal/messages/" + this.state.deal_id} />
    };

    return (
        <div>
      <Row>
        <Col sm={12} xs={12} lg={12}>
        <font size="2">
            <Table striped bordered hover responsive size="md">
              <thead>
                <tr>
                  <th>Дата</th>
                  <th>Тип продажи</th>
                  <th>Валюта продажи</th>
                  <th>Сумма продажи</th>
                  <th>Валюта покупки</th>
                  <th>Сумма покупки</th>
                  <th>Курс</th>
                  <th>Тип покупки</th>
                  <th>Страна</th>
                  <th>Регион</th>
                  <th>Город</th>
                  <th>Статус</th>
                </tr>
              </thead>
              <tbody>
                  {Object.keys(this.state.deal_list).map((index) => (
                    <tr style={styles.tr} onClick={() => (this.rowClick(this.state.deal_list[index]._id))}>
                      <td>{UserDate(this.state.deal_list[index].date)}</td>
                      <td>{types[this.state.deal_list[index].type_from]}</td>
                      <td bgcolor='#ffe9e5'>{this.state.deal_list[index].currency_from}</td>
                      <td bgcolor='#ffe9e5'>{this.state.deal_list[index].sum_from}</td>
                      <td bgcolor='#dafff5'>{this.state.deal_list[index].currency_to}</td>
                      <td bgcolor='#dafff5'>{this.state.deal_list[index].sum_to}</td>
                      <td bgcolor='#fffacb'>{(this.state.deal_list[index].sum_to / this.state.deal_list[index].sum_from).toFixed(2)}{" | "}{(this.state.deal_list[index].sum_from / this.state.deal_list[index].sum_to).toFixed(2)}</td>
                      <td>{types[this.state.deal_list[index].type_to]}</td>
                      <td>{this.state.deal_list[index].country.name_ru}</td>
                      <td>{this.state.deal_list[index].region.name_ru}</td>
                      <td>{this.state.deal_list[index].city.name_ru}</td>
                      <td>{statuses[this.state.deal_list[index].status]}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            </font>
        </Col>
    </Row>
    </div>
    )
}}


export default DealList
