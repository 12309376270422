import React, { Component } from 'react';
import { Container, Col, Row, Button, ListGroup, Form, Jumbotron } from 'react-bootstrap';
import Backend from '../backend';
import {UserDate} from '../utils';


export class DealListMy extends Component {

state = {
    deal_list: [],
    welcome: false,
    hidden: true
}

componentWillMount = () => {
  Backend.request("/api/deal/my").then((data) => {
    if (data.list.length > 0) {
      this.setState({ deal_list: data.list, welcome: true });
    } else {
      this.setState({welcome: true})
    };
  });
}

statusChange = (e) => {
  let _id = e.target.name;
  let status = e.target.value;
  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify({
      '_id': _id,
      'status': status,
    })
  };

  Backend.request('/api/deal/my', requestOptions.method, requestOptions.body).then(data => {
    this.setState({ deal_list: data.list });
  });
}

deleteClick = (e) => {
  const requestOptions = {
    method: 'DELETE',
    body: JSON.stringify({
      '_id': e,
    })
  };

  Backend.request('/api/deal/my', requestOptions.method, requestOptions.body).then(data => {
    this.setState({ deal_list: data.list })
  });
}


render() {

    let hidden = this.state.hidden
    if (this.state.deal_list.length === 0 && this.state.welcome) {
      hidden = false
    }

    const types = {
      "cash": "Наличные",
      "card": "Карта",
      "country_bank": "Банк",
      "word_bank": "Иност Банк",
    };

    return (
      <Container>
      <Container>
      <br />
      <Row>
        <Col sm={12}>
          {Object.keys(this.state.deal_list).map((index) => (
            <Row>
            <ListGroup as={Col} sm={7} horizontal='md' className="my-1">
              <ListGroup.Item as={Col}>{UserDate(this.state.deal_list[index].date)}</ListGroup.Item>
              <ListGroup.Item variant="danger" as={Col}>{this.state.deal_list[index].sum_from} {this.state.deal_list[index].currency_from} {types[this.state.deal_list[index].type_from]}</ListGroup.Item>
              <ListGroup.Item variant="success" as={Col}>{this.state.deal_list[index].sum_to} {this.state.deal_list[index].currency_to} {types[this.state.deal_list[index].type_to]}</ListGroup.Item>
              <ListGroup.Item variant="warning" as={Col}>{(this.state.deal_list[index].sum_to / this.state.deal_list[index].sum_from).toFixed(2)}{" | "}{(this.state.deal_list[index].sum_from / this.state.deal_list[index].sum_to).toFixed(2)}</ListGroup.Item>
            </ListGroup>
            <ListGroup as={Col} sm={5} horizontal='md' className="my-1">
              <Col>
                  <Form.Group className="my-1">
                    <Form.Control name={this.state.deal_list[index]._id} as="select" onChange={this.statusChange} value={this.state.deal_list[index].status}>
                      <option value="open">Открыто</option>
                      <option value="reserve">Выполняется</option>
                      <option value="moderate">Исполнено</option>
                      <option value="refuse">Отказ</option>
                    </Form.Control>
                  </Form.Group>
              </Col>
              <Col className="my-1">
                  <Button variant="danger" onClick={() => {this.deleteClick(this.state.deal_list[index]._id)}}>Удалить</Button>
              </Col>
            </ListGroup>
            </Row>
          ))}
        </Col>
      </Row>
          <div hidden={hidden}>
            <Jumbotron>
              <h1>Пусто</h1>
              <br />
              <p>
                Вы еще не создали ни одного объявления
              </p>
              <br />
              <p>
                  <Button variant="primary" href="/#/deal/new">Разместить объявление</Button>
              </p>
            </Jumbotron>
          </div>
      </Container>
      </Container>
    )
}}

export default DealListMy
