import React, { Component } from 'react';
import { Form, Container, Col, Row, Button, Alert } from 'react-bootstrap';
import Backend from '../../backend';

export class ObjectField extends Component {
  state = {
    form: {}
  }
  render() {
    const schema = this.props.schema;
    const onchange = this.props.onchange;
    const values = this.props.values;
    const types = this.props.types;

    return (
      <div>
        {Object.keys(schema).map((key_) => (
          <Form.Group as={Row} controlId={key_}>
            <Form.Label column sm="4">{schema[key_]}</Form.Label>
            <Col sm="8">
              <Form.Control name={key_} onChange={onchange} value={values[key_]} required type={types[key_]}></Form.Control>
            </Col>
          </Form.Group>
        ))}
      </div>
    )
  }
};


class ObjectListField extends Component {
  state = {
    form: {}
  }
  render() {
    let schema = this.props.schema;
    let options = this.props.options;
    let onchange = this.props.onchange;
    let selected = this.props.selected;
    let selected_value = "";
    if (selected.hasOwnProperty(schema.key)) {
      selected_value = selected[schema.key]._id
    };
    return (
      <div>
        <Form.Group as={Row} controlId={schema.key}>
          <Form.Label column sm="4">{schema.value}</Form.Label>
          <Col sm="8">
            <Form.Control as="select" name={schema.key} onChange={onchange} required value={selected_value}>
              <option hidden value> -- выберите -- </option>
              {Object.keys(options).map((option) => (
                <option value={option}>{options[option]}</option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
      </div>
    )
  }
};


export class BaseComponent extends Component {
  state = {
    alert: false
  }
  hideAlert = () => {
    this.setState({ alert: false })
  }

  showAlert = () => {
    this.setState({ alert: true })
  }
}

export class AlertDismissible extends Component {

  state = {
    alert: false
  }

  render() {
    const show = this.props.show
    const hide = this.props.hide;
    return (
      <div>
        <Alert show={show} variant="success" onClose={hide} dismissible>
          Готово!
          </Alert>
      </div>
    )
  }
};

export class ObjectFormBase extends BaseComponent {
  state = {
    schema: {},
    list_schema: [],
    schema_url: "",
    api_url: "",
    form: {},
    alert: false,
    object_id: "",
    request_method: "POST",
    item: {},
    types: {},
    edit: false
  }

  componentDidMount = () => {
    const object_id = this.props.match.params.object_id;
    let request_method = "POST"
    if (object_id !== undefined) {
      request_method = "PUT";
      Backend.request(`${this.state.api_url}?object_id=${object_id}`).then(data => {
        this.setState({ form: data.item });
      })
    } else if (this.state.edit === true) {
      request_method = "PUT";
      Backend.request(`${this.state.api_url}`).then(data => {
        this.setState({ form: data.item });
      })
    };
    if (this.state.schema_url) {
      Backend.request(this.state.schema_url).then((schema_data) => {
        this.setState({ schema: schema_data.schema, list_schema: schema_data.list_schema, types: schema_data.types, request_method: request_method })
      })
    };
  }
  onChange = (e) => {
    let form = this.state.form;
    let temp = e.target.value;
    let type_name = e.target.name
    if (this.state.types[type_name] === 'number') {
      temp = Number(e.target.value);
    }
    form[e.target.name] = temp
    this.setState({ form: form });
  }

  handleSubmit = () => {
    console.log(this.state.request_method);
    const requestOptions = {
      method: this.state.request_method,
      body: JSON.stringify(this.state.form)
    };

    Backend.request(this.state.api_url, requestOptions.method, requestOptions.body).then(data => {
      this.setState({ result: data.result, alert: true })
    })
  }
}


export class ObjectFormContainer extends ObjectFormBase {

  state = {
    alert: false,
    title: ""
  }

  render() {
    let list_schema = this.state.list_schema;
    return (
      <Container>
        <Row>
          <Col column sm="12">
            <div><br />
              <AlertDismissible show={this.state.alert} hide={this.hideAlert} />
              <h2>{this.state.title}</h2>
              <br />
            </div>
            <Form onSubmit={(e) => { this.handleSubmit(); e.preventDefault(); }}>
              {Object.keys(list_schema).map((key_) => (
                <ObjectListField schema={list_schema[key_].schema} options={list_schema[key_].values} onchange={this.onChange} selected={this.state.form}></ObjectListField>
              ))}
              <ObjectField schema={this.state.schema} onchange={this.onChange} values={this.state.form} types={this.state.types}></ObjectField>
              <Col md={{ span: 4, offset: 4 }}>
                <Button size="lg" variant="primary" type="submit">Сохранить</Button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default ObjectFormContainer;