import React, { Component } from 'react';
import { Form, Container, Col, Row, Button, Alert } from 'react-bootstrap';
import Backend from '../backend';
import {Redirect} from 'react-router-dom';


export class DealDetails extends Component {
  state = {
    deal: {}
  }

  componentDidMount = () => {
    Backend.request("/api/deal/" + this.props.match.params.deal_id).then((data) => {
      this.setState({ deal: data.deal });
    });
  }

  render() {
    return(
      <Container>
      <Row>
        <Col sm={12}>

        </Col>
      </Row>
      </Container>
    )
  }

}


export class DealForm extends Component {

state = {
    "country_list": [],
    "region_list": [],
    "city_list": [],
    "country": null,
    "region": null,
    "city": null,
    "currency_list": [],
    "currency_from": "USD",
    "currency_to": "BYR",
    "sum_from": null,
    "sum_to": null,
    "type_from": "cash",
    "type_to": "cash",
    "result": null,
}

componentDidMount = () => {
  Backend.request("/api/location/current").then((data) => {
    this.setState({ country: data.country || "RU", region: data.region || null, city: data.city || null});
  }).then(
    Backend.request("/api/location/country/list").then((data) => {
      this.setState({ country_list: data.list });
    }).then(() => {
        Backend.request("/api/location/region/list/" + this.state.country).then((data) => {
          this.setState({ region_list: data.list, city_list: [] });
        }).then(() => {
          if (this.state.region) {
            Backend.request("/api/location/city/list/" + this.state.region).then((data) => {
              this.setState({ city_list: data.list });
            })
          }
        })
      }
    )
  );
  Backend.request("/api/currency/list").then((data) => {
    this.setState({ currency_list: data.list });
  });
}

countryChange = (e) => {
  let country = e.target.value;
  this.setState({country})
  console.log(country);
  Backend.request("/api/location/region/list/" + country).then((data) => {
    this.setState({ region_list: data.list, city_list: [], region: null, city: null });
  });
}

regionChange = (e) => {
  let region = Number(e.target.value.replace(/\D/,''));
  this.setState({region})
  console.log(region);
  Backend.request("/api/location/city/list/" + region).then((data) => {
    this.setState({ city_list: data.list, city: null });
  });
}

cityChange = (e) => {
  let city = Number(e.target.value.replace(/\D/,''));
  if (city === 'none') { city = null }
  this.setState({city})
}

currency_fromChange = (e) => {
  let currency_from = e.target.value;
  this.setState({currency_from})
}

currency_toChange = (e) => {
  let currency_to = e.target.value;
  this.setState({currency_to})
}

sum_fromChange = (e) => {
  let sum_from = Number(e.target.value.replace(/\D/,''));
  if (sum_from === 0) {sum_from = null}
  this.setState({sum_from})
}

sum_toChange = (e) => {
  let sum_to = Number(e.target.value.replace(/\D/,''));
  if (sum_to === 0) {sum_to = null}
  this.setState({sum_to})
}

type_fromChange = (e) => {
  let type_from = e.target.value;
  this.setState({type_from})
}

type_toChange = (e) => {
  let type_to = e.target.value;
  this.setState({type_to})
}

handleSubmit = () => {

  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({
      'currency_from': this.state.currency_from,
      'sum_from': this.state.sum_from,
      'currency_to': this.state.currency_to,
      'sum_to': this.state.sum_to,
      'type_from': this.state.type_from,
      'type_to': this.state.type_to,
      'country': this.state.country,
      'region': this.state.region,
      'city': this.state.city,
    })
  };

  Backend.request('/api/deal', requestOptions.method, requestOptions.body).then(data => {
    this.setState({ result: data.result })
  });
}

render() {
    if (this.state.result) {
      return <Redirect to="/deal/list" />
    }

    let rate = "";
    let rate_rev = ""
    let rate_alert = true
    if (this.state.sum_from && this.state.sum_to) {
      rate = (this.state.sum_to / this.state.sum_from).toFixed(2);
      rate_rev = (this.state.sum_from / this.state.sum_to).toFixed(2);
      rate_alert = false;
    }

    return (
      <Container>
      <Container>
      <Row>
        <Col sm={12} xs={12} lg={{ span: 6, offset: 3 }}>
        <Form className="align-items-center" onSubmit={(e) => { this.handleSubmit(); e.preventDefault(); }}>
          <br />
          <Row><h3>У меня есть</h3></Row>
          <Form.Group as={Row}>
            <Form.Label>Выберите валюту</Form.Label>
            <Form.Control as="select" size="lg" required onChange={this.currency_fromChange} value={this.state.currency_from}>
              {Object.keys(this.state.currency_list).map((index) => (
                <option value={this.state.currency_list[index].STRCODE}>{this.state.currency_list[index].NAME}</option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label>Укажите сумму</Form.Label>
            <Form.Control type="number" size="lg" required onChange={this.sum_fromChange} value={this.state.sum_from} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label>Формат</Form.Label>
            <Form.Control as="select" size="lg" required onChange={this.type_fromChange} value={this.state.type_from}>
              <option value="cash">Наличные</option>
              <option value="card">Перевод по карте</option>
              <option value="country_bank">Банковский счёт внутри страны</option>
              <option value="word_bank">Зарубежный банковский счёт</option>
            </Form.Control>
          </Form.Group>

          <br />
          <Row><h3>Я хочу</h3></Row>
          <Form.Group as={Row}>
            <Form.Label>Выберите валюту</Form.Label>
            <Form.Control as="select" size="lg" required onChange={this.currency_toChange} value={this.state.currency_to}>
              {Object.keys(this.state.currency_list).map((index) => (
                <option value={this.state.currency_list[index].STRCODE}>{this.state.currency_list[index].NAME}</option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label>Укажите сумму</Form.Label>
            <Form.Control type="number" size="lg" required onChange={this.sum_toChange} value={this.state.sum_to} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label>Формат</Form.Label>
            <Form.Control as="select" size="lg" required onChange={this.type_toChange} value={this.state.type_to}>
              <option value="cash">Наличные</option>
              <option value="card">Перевод по карте</option>
              <option value="country_bank">Банковский счёт внутри страны</option>
              <option value="word_bank">Зарубежный банковский счёт</option>
            </Form.Control>
          </Form.Group>
          <br />
            <Alert variant="warning" hidden={rate_alert}><center>Курс обмена: <b>{rate}{" | "}{rate_rev}</b></center></Alert>
          <br />
          <Row><h3>Место обмена</h3></Row>
          <Form.Group as={Row}>
            <Form.Label>Страна</Form.Label>
            <Form.Control as="select" size="lg" required onChange={this.countryChange} value={this.state.country}>
              {Object.keys(this.state.country_list).map((index) => (
                <option value={this.state.country_list[index].iso}>{this.state.country_list[index].name_ru}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label>Регион</Form.Label>
            <Form.Control as="select" size="lg" required onChange={this.regionChange} value={this.state.region}>
              <option value={'none'}>Не важно</option>
              {Object.keys(this.state.region_list).map((index) => (
                <option value={this.state.region_list[index].id}>{this.state.region_list[index].name_ru}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label>Город</Form.Label>
            <Form.Control as="select" size="lg" onChange={this.cityChange} value={this.state.city}>
              <option value={'none'}>Не важно</option>
              {Object.keys(this.state.city_list).map((index) => (
                <option value={this.state.city_list[index].id}>{this.state.city_list[index].name_ru}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <br />
          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
            <Row>
              <Button type="submit" size="lg" variant="success" block>Разместить</Button>
            </Row>
            </Col>
          </Row>
        </Form>
        </Col>
      </Row>
      </Container>
      </Container>
    )
}
}

export default DealForm