export const UserDate = (timestamp) => {
    const now = new Date();
    const dif = now.getTimezoneOffset()
    const locale = navigator.language;
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false };
    // const date = new Date(timestamp * 1000 - dif * 60000);
    // const date = new Date(timestamp * 1000);
    let date = new Date(0);
    date.setUTCSeconds(timestamp);
    const idate = date.toLocaleString(locale, options)
    return idate;
};
