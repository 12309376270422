import React, {Component} from 'react';
import { Container, Card, ListGroup, Row, Col, Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import Backend from '../../backend';
import Pagination from './pagination';


class ListItems extends Component {
  render() {
    let schema = this.props.schema;
    let values = this.props.values;
    let list_schema = this.props.list_schema;
    let schema_name = this.props.schema_name;
    let schema_url_type = '/id/';

    return (
        <ListGroup>
          {Object.keys(values).map((key_values) => (
            <Card className="mt-3" id={values[key_values]._id}>
              <Card.Body>
                <Card.Title>{values[key_values].title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{values[key_values].subtitle}</Card.Subtitle>
                <Card.Text>
                {Object.keys(list_schema).map((key_list_schema) => (
                  <div><span><b>{list_schema[key_list_schema].schema.value}:</b> <a href={'#/' + list_schema[key_list_schema].schema.key + schema_url_type + values[key_values][list_schema[key_list_schema].schema.key]._id }>{values[key_values][list_schema[key_list_schema].schema.key].name}</a></span><br/></div>
                ))}
                {Object.keys(schema).map((key_schema) => (
                  <div><span><b>{schema[key_schema]}:</b> {values[key_values][key_schema]}</span><br/></div>
                ))}
                </Card.Text>
                <Card.Link href={'#/' + schema_name + schema_url_type + values[key_values]._id}>Перейти</Card.Link>
              </Card.Body>
            </Card>
          ))}
        </ListGroup>
    )}
};


export class BaseObjectList extends Component {
  state = {
    schema_url: "",
    api_url: "",
    schema: {},
    list_schema: [],
    values: [],
    current_values: [],
    currentPage: null,
    totalPages: null,
    alert: false,
    title: "",
    schema_name: null,
    summary: [],
    filteredValues: [],
  }

  componentDidMount() {
    Backend.request(this.state.api_url).then((data) => {
      this.setState({ values: data.values, filteredValues: data.values });
    });

    Backend.request(this.state.schema_url).then((schema_data) => {
      this.setState({
        schema: schema_data.schema,
        list_schema: schema_data.list_schema,
        schema_name: schema_data.name,
        summary: schema_data.summary
      }, 
      );
    });
  }

  onPageChanged = (data) => {
    let values = this.state.filteredValues;
    let { currentPage, totalPages, pageLimit } = data;

    let offset = (currentPage - 1) * pageLimit;
    let current_values = values.slice(offset, offset + pageLimit);

    this.setState({ currentPage, current_values, totalPages });
  }

  handleChange = (data) => {
    this.setState({ query: data.target.value })
    const values = this.state.values;
    let filteredValues = values.filter((item) => {
      let itemName = JSON.stringify(item).toLowerCase()
      return itemName.includes(data.target.value.toLowerCase());
    });
    console.log(filteredValues);
    this.setState({
      filteredValues
    });
    this.onPageChanged({
      currentPage: this.state.currentPage,
      totalPages: this.state.totalPages,
      pageLimit: 10,
      totalRecords: this.state.totalRecords
    });
  }

  render() {
    let {
      values,
      current_values,
      schema_name,
      summary,
    } = this.state;

    let total_values = values.length;

    const sumItems = (items, key) => {
        const sum_values = (total, num) => {
          return total + num[key];
        };
        return items.reduce(sum_values, 0);
    };

    const PPagin = (total_values, onPageChanged) => {
      if (total_values === 0) return null;
      return (
        <Pagination
          totalRecords={total_values}
          pageLimit={10}
          pageNeighbours={1}
          onPageChanged={onPageChanged}
        />
      )
    };

    return (
      <div>
        <Container>
        <Row>
        <Col>
          <Row>
            <div className="w-100 px-4 py-12 d-flex flex-row flex-wrap align-items-center justify-content-between">
                <div className="d-flex flex-row align-items-center">
                  <h2>
                    <strong className="text-secondary">{this.state.title} ({total_values})</strong>{" "}
                    <Button size="sm" variant="link" href={'/#/' + schema_name + "/new"}>создать</Button>
                  </h2>
                </div>
                <div>

                </div>
              <div className="d-flex flex-row py-4 align-items-center">
                {PPagin(total_values, this.onPageChanged)}
              </div>
            </div>
          </Row>
        <Row>
          <Col>
            <Form onSubmit={(e) => { e.preventDefault(); }}>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Поиск"
                aria-label="Поиск"
                name="query"
                id="query"
                onChange={this.handleChange}
              />
            </InputGroup>
          </Form>
          <Card bg='info' className="mt-3">
            <ListGroup variant="flush">
              {Object.keys(summary).map((index) => (
                <ListGroup.Item>{summary[index].title}: {sumItems(values, summary[index].name)}</ListGroup.Item>
              ))}
            </ListGroup>
          </Card>
          </Col>
        </Row>
        <ListItems values={current_values} schema={this.state.schema} list_schema={this.state.list_schema} api_url={this.state.api_url} schema_name={schema_name}></ListItems>
        </Col>
        </Row>
        </Container>
      </div>
    );
  }
}


// ====================================================

export class BaseObjectList_bkp extends Component {
  state = {
    schema_url: "",
    api_url: "",
    schema: {},
    list_schema: [],
    values: [],
    alert: false,
    title: "",
  }

  componentDidMount() {
    Backend.request(this.state.api_url).then((data) => {
      this.setState({values: data.values});
    })

    Backend.request(this.state.schema_url).then((schema_data) => {
      this.setState({ schema: schema_data.schema, list_schema: schema_data.list_schema });
    })
  }

  render() {
    return (
      <div>
        <Container>
        <br/>
        <h2>{this.state.title}</h2>
        <br/>
        <ListItems values={this.state.values} schema={this.state.schema} list_schema={this.state.list_schema} api_url={this.state.api_url}></ListItems>
        </Container>
      </div>
    )
  }
}

//=====================================================

export class BaseObjectItem extends Component {
  state = {
    api_url: "/api/",
    item: Object,
    schema: Object,
    list_schema: [],
    relationship: null,
  }

  componentDidMount() {
    const object_id = this.props.match.params.object_id;
    const object_type = this.props.match.params.object_type;
    Backend.request(this.state.api_url + object_type + '?object_id=' + object_id).then((data) => {
      this.setState({
        item: data.item,
        schema: data.schema.schema,
        list_schema: data.schema.list_schema,
        object_id: object_id, object_type: object_type,
        relationship: data.schema.relationship
      });
    })
  }

  render() {
    let item = this.state.item;
    let schema = this.state.schema;
    let list_schema = this.state.list_schema;
    return (
      <div>
        <Container>
        <br/>
          <Card style={{ width: 'auto' }} id={item._id}>
            <Card.Body>
              <Card.Title>{item.title}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{item.subtitle}</Card.Subtitle>
              <Card.Text>
                {Object.keys(list_schema).map((key_list_schema) => (
                  <div><span><b>{list_schema[key_list_schema].schema.value}:</b> {item[list_schema[key_list_schema].schema.key].name}</span><br/></div>
                ))}
                {Object.keys(schema).map((key_schema) => (
                  <div><span><b>{schema[key_schema]}:</b> {item[key_schema]}</span><br/></div>
                ))}
              </Card.Text>
              <Card.Link href={'#/' + this.state.object_type + '/edit/' + this.state.object_id}>Изменить</Card.Link>
            </Card.Body>
          </Card>
        </Container>
      </div>
    )
  }
}

export default BaseObjectList;
